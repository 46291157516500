@use "../variables";

.promotions__title {
    color: variables.$white;
    padding: 16px 16px 16px 0;
    height: 200px;
    align-content: center;
    line-height: 44px;
    font-size: variables.$font-size-5xl;

    @if variables.$site-avi {
        height: 160px;
    }
    
    &-background {
        background: variables.$primary;
        background-image: url(/images/promotions-header.svg);
        background-repeat: no-repeat;
        background-position: right center;
        background-size: contain;
        
        @if variables.$site-nova {
            background: variables.$primary-darker;
            background-image: url(/images/novastar/background.png);
            background-position: center;
            background-attachment: local;
        }

        @if variables.$site-avi {
            background: variables.$supporting-02;
            background-image: url(/images/avitela/promotions-header.svg);
            background-repeat: no-repeat;
            background-position: right 15% center;
        }
    }
}

.promotion {
    &__title {
        color: variables.$primary;
        padding: 30px 0;
        font-size: variables.$font-size-xl;
        
        &-background {
            background: variables.$neutral-lighter-25;
            @if variables.$site-avi {
                background: variables.$neutral-lighter-15;
            }
        }
    }
    
    &__item {        
        .promotion__title-background {
            background: variables.$neutral-lighter-25;
            @if variables.$site-nova-or-avi {
                background: variables.$white;
            }
        }
        
        .banner__img {
            padding: 0;
        }
    }
    
    &__button {
        background-color: variables.$white;
        border: solid 2px variables.$neutral-lighter-25;
        border-radius: 100px;
        font-weight: variables.$font-weight-normal;
        margin-bottom: 30px;
        
        @if variables.$site-nova {
            border-radius: 6px;
        }
    }
    
    &__login{

        &-container{
            background-color: variables.$primary-light;
            display: flex;
            justify-content: center;
            
            @if variables.$site-nova {
                background-color: variables.$primary-light-10;
            }
            @if variables.$site-avi{
                background: variables.$neutral-lighter-15;
            }
        }
        &-wrapper{
            display: flex;
            width: 1216px;
            justify-content: center;
        }
        &-content{
            color: variables.$white;
            display: flex;
            flex-direction: column;
            align-self: center;
            align-items: flex-start;
            width: inherit;
            
            @if variables.$site-avi {
                color: variables.$primary;
            }
            
            .button{
                padding: 0 16px;
            }

            > * {
                padding: 20px 0;
            }
            
            h2{
                font-size: variables.$font-size-xl;
                color: variables.$white;
                @if variables.$site-avi {
                    color: variables.$primary;
                }
            }
            .promotion__login-description {
                color: variables.$white;
                @if variables.$site-avi {
                    color: variables.$primary;
                }
            }
        }
        &-icon{
            height: 220px;
            content: url('/images/promotions-user-icon.svg');
            z-index: 1;
            position: relative;
            @if variables.$site-nova {
                padding: 0;
                content: url('/images/novastar/promotions-user-novanaut.png');
            }
            @if variables.$site-avi {
                padding: 60px 120px 60px 40px;
                content: url('/images/avitela/avitela_login_logo.svg');

                &-extra {
                    content: url('/images/avitela/promotions-login-logo-swirl.svg');
                    position: absolute;
                    height: 340px;
                    right: 0;
                }
            }
            &-container{
                align-self: center;
                position: relative;
                height: 340px;
                align-content: center;
            }
        }
    }
    
    &__consultation{
        &-container{
            background-color: variables.$primary-light-10;
            justify-items: center;
            @if variables.$site-avi { 
                background-color: variables.$neutral-lighter-15;
            }
        }
        
        &-wrapper{
            width: 1216px;
            display: grid;
            grid-template-columns: 6fr 4fr;
            grid-template-areas: "text image";
            
            .salesman {
                &__content {
                    display: flex;
                    position: relative;
                    justify-content: center;

                    &-wrapper {
                        display: grid;
                        grid-template-columns: 1fr;
                        overflow: hidden;
                        height: 260px;
                    }
                }

                &__background {
                    position: absolute;
                    width: 400px;
                    height: 200px;
                    margin: 0 auto;
                    z-index: 0;
                    bottom: 0;
                    border-radius: 400px 400px 0 0;
                    background: variables.$white;
                }

                &__image {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: top;
                    z-index: 1;
                }
            }
        }
        
        &-content{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            align-self: center;
            
            h2{
                font-size: variables.$font-size-xl;
                color: variables.$white;
            }
            .promotion__consultation-description {
                color: variables.$white;
            }
            > * {
                padding: 20px 0;
            }

            button {
                padding: 0 16px;
            }
        }
    }
}

.campaign-products {
    &__list {
        .items-carousel__list {
            display: inline-flex;
            width: 100%;

        }
    }
}

.categories {
    &-background {
        background: variables.$neutral-lighter-25;
        @if variables.$site-nova-or-avi {
            border-top: 1px solid variables.$neutral-lighter-100;
        }
        @if variables.$site-avi {
            background: variables.$neutral-lighter-15;
        }
    }
    
    &__list {
        display: grid;
        box-shadow:
            0 1px 0 0 variables.$neutral-lighter-100,
            inset -1px 0 0 0 variables.$neutral-lighter-100;
        
        .category {
            &__item {
                background: variables.$white;
                box-shadow: 
                    inset 1px 0 0 0 variables.$neutral-lighter-100, 
                    inset 0 1px 0 0 variables.$neutral-lighter-100;
                
                &:hover {
                    box-shadow: 
                        inset 1px 0 0 0 variables.$primary, 
                        inset 0 1px 0 0 variables.$primary;
                    background: variables.$primary;
                    @if variables.$site-nova {
                        background: variables.$primary-light-10;
                    }
                    a{
                        color: variables.$white;
                    }
                    .category-name {
                        position: relative;
                        bottom: -25px;
                    }
                }
            }
            
            &__link {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                
                .icon-hover {
                    display: none;
                }
                
                &:hover {
                    .icon-idle {
                        display: none;
                    }
                    
                    .icon-hover {
                        display: block;
                    }
                }
            }
            
            &-name {
                padding: 10px 10px 0;
                text-align: center;
            }
        }
    }
}

.campaign-product-cards-container {
    padding: 10px;
    .campaign-product-cards-block{
        &__item{
            display: -webkit-box;
        }
        &__wrapper{
            place-items: center;
            display: grid;
        }
        &__list {
            display: grid;
            border-style: solid none none solid;
            border-width: 1px;
            border-color: variables.$neutral-lighter-100;
            max-width: 1216px;

            @if variables.$site-nova-or-avi {
                border-style: none;
            }
            .product-card {
                max-width: -webkit-fill-available;
                
                .compare,.compare-landscape {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: variables.$responsive-break - 1) {
    .promotions__title {
        height: auto;
        padding: 30px;

        &-background {
            background-image: url(/images/promotions-header__mobile.svg);
            background-size: auto;
            background-position: right calc(100% - 20px);
            padding-bottom: 220px;
            @if variables.$site-nova {
                background-image: url(/images/novastar/background.png);
                padding-bottom: 50px;
                background-position: center;
                background-attachment: fixed;
            }
            @if variables.$site-avi {
                padding-bottom: 250px;
                background-image: url(/images/avitela/promotions-header__mobile.svg);
            }
        }
    }
    
    .promotion__title {
        padding: 10px;
    }
    
    .promotion__login{
        &-wrapper{
            width: 100%;
            flex-direction: column;
        }
        &-content{
            text-align: center;
            align-items: center;
            h2 {
                margin-top: 20px;
            }
            > * {
                padding: 20px;
            }
            
            button {
                margin: 0 20px;
            }
        }
        &-icon{
            padding: 20px;
            height: 160px;
            max-width: 100%;
            
            @if variables.$site-nova {
                padding: 0;
                align-self: center;
                height: inherit;
            }
            @if variables.$site-avi {
                content: url('/images/avitela/promotions-logo__mobile.svg');
                display: block;
                height: 200px;
                &-extra {
                    display: none;
                }
            }
            
            &-container{
                height: 220px;
                @if variables.$site-avi {
                    height: 240px;
                    align-self: center;
                }
            }
        }
    }
    
    .categories-container {
        padding: 16px 16px 32px 16px;
        
        .categories__list {
            grid-template-columns: repeat(2, 1fr);
            
            .category__link {
                height: 150px;
            }
        }
    }

    .promotion__consultation{
        &-wrapper{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-items: center;
        }
        &-content{
            align-items: center;
            text-align: center;
            h2 {
                margin-top: 20px;
            }
            > * {
                padding: 20px;
            }
        }
    }
    
    .campaign-product-cards-container {
        .campaign-product-cards-block{
            &__list {
                grid-template-columns: repeat(2, 1fr);
                @if variables.$site-nova-or-avi {
                    column-gap: 10px;
                }

                .product-card {
                    border-style: none solid solid none;
                    border-width: 1px;
                    border-color: variables.$neutral-lighter-100;
                    border-radius: 0;
                    min-height: unset;
                    
                    @if variables.$site-nova {
                        max-width: unset;
                    }
                    .btn-addtocart {
                        position: absolute;
                        padding: 10px 0;
                        right: 10px;
                        bottom: 0;
                    }
                    .add-to-cart-container{
                        .nova-head{
                            right: 8px;
                        }
                    }
                }
            }

            .items{
                &--1{
                    grid-template-columns: repeat(1, 1fr);
                }
            }

            &__item {
                min-height: unset;
                max-width: unset;
                width: 100%;
                @if variables.$site-nova-or-avi {
                    padding: 5px;
                    width: 98%;
                }
            }
        }
    }
    
    .promotion__item {
        // Rendering image exactly the size of screen width. Needed for IntersectionObserver threshold to work. 
        .banner__img{
            width: 100%;
            height: 300px;
            object-fit: cover;
            display: block;
        }
        .items-carousel__list {
            li:nth-child(n+4 of .tns-slide-active) {
                display: none;
            }
            
            .items-carousel__item {
                margin-bottom: 0;
                
                &:not(.tns-slide-active) {
                    display: none;
                }
                
                .carousel-card {
                    margin: 0 10px 10px;
                }
            }
        }
    }
}

@media (max-width: variables.$mobile-break - 1) {
    .campaign-product-cards-container {
        .campaign-product-cards-block{
            &__list {
                grid-template-columns: repeat(1, 1fr);
            }
            
            .product-card__price{
                padding-bottom: 55px;
                @if variables.$site-nova{
                    padding-right: 55px;
                }
            }
        }
    }
    .promotion__consultation-wrapper{
        .salesman {
            &__content-wrapper {
                height: 240px;
            }

            &__background {
                width: 360px;
                height: 180px;
                border-radius: 200px 200px 0 0;
                max-width: 100%;
            }
        }
    }
    
    .category-icon{
        max-height: 100px;
        max-width: 100px;
    }
}

@media (min-width: variables.$responsive-break) {
    .campaign-products__list {
        .items-carousel__list {
            border-style: none solid solid none;
            border-width: 1px;
            border-color: variables.$neutral-lighter-100;

            .carousel-card {
                border-style: solid none none solid;
                border-width: 1px;
                border-color: variables.$neutral-lighter-100;

                &:hover {
                    border: solid 1px variables.$primary-light;
                }
            }
        }
    }
    
    .categories-container {
        padding: 40px 150px;
        
        .categories__list {
            grid-template-columns: repeat(5, 1fr);
            
            .category__link {
                height: 200px;
            }
        }
    }

    .campaign-product-cards-container {
        .campaign-product-cards-block{
            &__list {
                grid-template-columns: repeat(4, 1fr);
            }

            .items{
                &--1{
                    grid-template-columns: repeat(1, 1fr);
                }
                &--2{
                    grid-template-columns: repeat(2, 1fr);
                }
                &--3{
                    grid-template-columns: repeat(3, 1fr);
                }
            }
        }
    }
}